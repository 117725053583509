var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        { staticStyle: { position: "relative" } },
        [
          _c("CommonTree", {
            attrs: {
              treeTitle: "组织架构",
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: false,
              showCheckbox: false,
              treeExpand: false,
              defaultCheckedKeys: _vm.defaultCheckedKeys,
              expandOnClickNode: false,
              nodeKey: "id",
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "div",
            { staticStyle: { width: "calc(100% - 275px)" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": _vm.headTitle,
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-save": _vm.headSave,
                  "head-refer": _vm.headSubmit,
                  "head-summary": _vm.headSummary,
                  "head-cancel": _vm.headCancel,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _vm.gridShow
                ? _c("grid-layout", {
                    ref: "gridLayOut",
                    attrs: {
                      "table-options": _vm.tableOption,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.tableLoading,
                      "data-total": _vm.page.total,
                      page: _vm.page,
                    },
                    on: {
                      "page-current-change": _vm.onLoad,
                      "page-size-change": _vm.onLoad,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "customBtn",
                          fn: function ({ row }) {
                            return [
                              row.dataStatus == 2 && row.templateClassify == "2"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "medium" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewReportUrl(row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看\n          ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      134462890
                    ),
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "summaryBox" },
                [
                  _c("div", { staticClass: "summaryTitle" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.summaryText) + "\n        "
                    ),
                  ]),
                  _vm._l(_vm.summaryList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "summaryLittle" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.lable) +
                            ":" +
                            _vm._s(item.value) +
                            "\n        "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
            _vm.summaryDiaShow
              ? _c("div", { staticClass: "iframeBox" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.summaryDiaShow = false
                            },
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.headSubmitReport },
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.headSubmit },
                        },
                        [_vm._v("提交")]
                      ),
                    ],
                    1
                  ),
                  _c("iframe", {
                    ref: "iframeSummary",
                    staticClass: "iframe",
                    attrs: { src: _vm.summaryUrl },
                  }),
                ])
              : _vm._e(),
          ]),
          _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
            _vm.iframeShow
              ? _c("div", { staticClass: "formContentBox" }, [
                  _c("div", { staticClass: "iframeBox1" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.iframeShow = false
                              },
                            },
                          },
                          [_vm._v("关闭")]
                        ),
                      ],
                      1
                    ),
                    _c("iframe", {
                      staticClass: "iframe",
                      staticStyle: { border: "none" },
                      attrs: { src: _vm.reportReviewPath },
                    }),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }