<template>
  <div>
    <el-container style="position: relative">
      <CommonTree
        treeTitle="组织架构"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        :showCheckbox="false"
        :treeExpand="false"
        :defaultCheckedKeys="defaultCheckedKeys"
        :expandOnClickNode="false"
        :nodeKey="'id'"
        @getNodeClick="treeNodeClick"
      />
      <div style="width: calc(100% - 275px);">
        <head-layout
          :head-title="headTitle"
          :head-btn-options="headBtnOptions"
          @head-save="headSave"
          @head-refer="headSubmit"
          @head-summary="headSummary"
          @head-cancel="headCancel"
        ></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          v-if="gridShow"
          ref="gridLayOut"
          :table-options="tableOption"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
          <template #customBtn="{row}">
            <el-button
              v-if="row.dataStatus == 2 && row.templateClassify == '2'"
              style="margin: 0 3px"
              type="text"
              size="medium"
              @click="viewReportUrl(row)"
            >查看
            </el-button>
          </template>
        </grid-layout>
        <div class="summaryBox">
          <div class="summaryTitle">
            {{ summaryText }}
          </div>
          <div class="summaryLittle" v-for="(item,index) in summaryList" :key="index">
            {{ item.lable }}:{{ item.value }}
          </div>
        </div>
      </div>
      <transition name="el-zoom-in-center">
        <div class="iframeBox" v-if="summaryDiaShow">
          <div style="display: flex;justify-content: flex-end">
            <el-button size="mini" @click="summaryDiaShow = false">关闭</el-button>
            <el-button size="mini" @click="headSubmitReport">保存</el-button>
            <el-button size="mini" @click="headSubmit">提交</el-button>
          </div>
          <iframe :src="summaryUrl" ref="iframeSummary" class="iframe"></iframe>
        </div>
      </transition>
      <transition name="el-zoom-in-center">
        <div class="formContentBox" v-if="iframeShow">
          <div class="iframeBox1">
            <div style="display: flex;justify-content: flex-end">
              <el-button size="mini" @click="iframeShow = false">关闭</el-button>
            </div>
            <iframe class="iframe" :src="reportReviewPath" style="border: none"></iframe>
          </div>
        </div>
      </transition>
    </el-container>
  </div>
</template>
<script>
import {
  summaryDetailsList,
  rpTaskAttributeReportClick,
  rpTaskAttributeSaveSummarize,
  rpTaskAttributeDetail, summaryAllList, rpTaskAttributeDetailReport, rpTaskAttributeView
} from "@/api/reportWrite";
import {getDetailByTaskId} from "@/api/reportTemplate";
import CommonTree from "@/views/components/com_tree";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {deptChildTree, templatePreview} from "@/api/reportTemplate";
import {mapGetters} from "vuex";
import draft from "@/views/plugin/workflow/mixins/draft";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import {Delete} from "@/api/workFlow/FlowEngine";
import {deleteChatRecord} from "@/api/permission/user";
import {dateFormat} from "@/util/date";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      treeData: [],
      defaultProps: {
        label: "fullName",
        value: "id",
      },
      templateClassify: '',
      searchTitle: 'fullName',
      summaryDiaShow: false,
      summaryUrl: '',
      iframeShow: false,
      reportReviewPath: '',
      type: '',
      fullName: '',
      weaveDept: '',
      title: '',
      titleName: '',
      headTitle: '',
      query: {},
      summaryText: '',
      summaryList: [],
      summaryType: 'bad',
      gridShow: false,
      rpTaskAttributeMap: {},
      defaultCheckedKeys: [],
      nowUserDeptId: '',
      tableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 200,
        menu: false,
        column: [
          {
            label: "任务标题",
            prop: "title",
            align: "center",
            overHidden: true,
            width: 300
          },
          {
            label: "填报/汇总时间",
            prop: "submissionDate",
            align: "center",
            width: 200
          },
          {
            label: "填报人",
            prop: "person",
            align: "center",
            width: 150
          },
          {
            label: "填报单位",
            prop: "fullName",
            align: "center",
            overHidden: true
          },
          {
            label: "填报/汇总",
            prop: "acquisitionTaskType",
            align: "center",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=Filling_Summary`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            label: "填报状态",
            prop: "dataStatus",
            align: "center",
            dataType: "number",
            width: 100,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=reportingStatus`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            label: "审批状态",
            prop: "status",
            align: "center",
            dataType: "number",
            width: 100,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=process_status`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true
          },
        ],
      },
      tableData: [],
      tableLoading: false,
      acquisitionTaskType: 'summary',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          prop: "title",
          span: 4,
          placeholder: '请输入任务标题'
        },
        {
          prop: "dataStatus",
          align: "center",
          dataType: "number",
          type: "select",
          span: 4,
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=reportingStatus`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
          placeholder: '请选择填报状态'
        },
        // {
        //   label: '下发时间',
        //   prop: "createTime",
        //   type: 'date-format',
        //   span: 4,
        //   placeholder: '请选择下发时间'
        // },
        // {
        //   label: '截至时间',
        //   prop: "deadlineSummaryDate",
        //   type: 'date-format',
        //   span: 4,
        //   placeholder: '请选择截至时间'
        // },
      ],
      processTemplateKey: "reportReview",
      form: {
        status: 1,
      },
      formProcess: {
        id: '',
        processId: '',
        data: {},
      },
    }
  },
  mixins: [exForm, draft],
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type) && [this.nowUserDeptId].includes(this.userInfo.dept_id) && ['bad', 'ok'].includes(this.summaryType)) {
        result.push(
          {
            label: "汇总",
            emit: "head-summary",
            type: "button",
          }
        );
        // result.push(
        //   {
        //     label: "保存",
        //     emit: "head-save",
        //     type: "button",
        //     btnOptType: "save",
        //   }
        // );
      }
      if (['add', 'edit'].includes(this.type) && [this.nowUserDeptId].includes(this.userInfo.dept_id) && ['ok'].includes(this.summaryType)) {
        result.push(
          {
            label: "提交",
            emit: "head-refer",
            type: "button",
            btnOptType: "refer",
          }
        );
      }
      result.push(
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        }
      );
      return result;
    },
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  mounted() {
    this.taskCode = this.$route.query.taskId;
    if (this.taskCode === undefined) {
      let type = this.$route.query.type;
      this.query = this.$route.query;
      this.fullName = this.query.fullName;
      this.weaveDept = this.query.weaveDept;
      this.templateClassify = this.query.templateClassify;
      this.titleName = this.query.title;
      this.type = type;
      //因为树结构刷新也会调用所以故此注释
      // this.onLoad(this.page, {});
      this.getTreeData();
      if (type == 'edit') {
        this.getProcessId();
        // this.headTitle = '填报';
      }
      if (type == 'view') {
        // this.headTitle = '查看';
      }
      this.getTemplatePreview();
      if (this.query.dataStatus == 1 || this.query.dataStatus == 2) {
        this.rpTaskAttributeDetail();
      }
      if (this.query.dataStatus == 1) {
        this.summaryType = 'ok';
      }
      if (this.query.dataStatus == 2) {
        this.summaryType = 'end';
      }
    } else {
      const taskId = this.taskCode
      getDetailByTaskId(taskId).then(res => {
        if (res.data.code === 200) {
          this.weaveDept = res.data.data.weaveDept;
          this.fullName = res.data.data.fullName
          this.title = res.data.data.title
          this.query.id = res.data.data.id
          this.query.captureFormId = res.data.data.captureFormId
          this.query.captureTaskId = res.data.data.captureTaskId
          this.query.unitId = res.data.data.unitId
          this.query.templateCode = res.data.data.templateCode
          this.query.dataStatus = res.data.data.dataStatus
          this.query.templateClassify = res.data.data.templateClassify
          this.templateClassify = res.data.data.templateClassify
          this.type = 'edit'
          this.getTreeData();
          this.getProcessId();
          this.getTemplatePreview();
          if (this.query.dataStatus == 1 || this.query.dataStatus == 2) {
            this.rpTaskAttributeDetail();
          }
          if (this.query.dataStatus == 1) {
            this.summaryType = 'ok';
          }
          if (this.query.dataStatus == 2) {
            this.summaryType = 'end';
          }
        }
      })
    }
  },
  methods: {
    //取消
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    //点击左侧组织架构
    treeNodeClick(node) {
      this.query.unitId = node.id;
      this.nowUserDeptId = node.id;
      this.gridHeadSearch(this.$refs.gridHeadLayout.searchForm)
    },
    //获取组织架构
    getTreeData() {
      deptChildTree().then((res) => {
        if (res.data.code == 200) {
          this.treeData.push(res.data.data)
          this.nowUserDeptId = this.treeData[0].id;
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.treeData[0].id)
          })
        }
      })
    },
    //报表模版填报跳转
    viewReportUrl(row) {
      if(row.acquisitionTaskType == 'fill'){
        rpTaskAttributeView(row.id).then((res) => {
          let dataForm = {};
          if (res.data.code === 200) {
            dataForm = res.data.data;
            rpTaskAttributeDetailReport(dataForm.templateCode).then((res) => {
              let date = this.time = dateFormat(new Date(), 'yyyy-MM-dd');
              this.reportReviewPath = res.data.data.reportReviewPath + '&create_date=' + date + '&id=' + dataForm.id + '&type=0' + '&create_dept=' + this.userInfo.dept_id;
              this.iframeShow = true;
            })
          }
        })
      }else {
        rpTaskAttributeReportClick(row).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            let templateCode = this.tableData[0].templateCode;
            rpTaskAttributeDetailReport(templateCode).then((respon) => {
              let dataRes = respon.data.data;
              this.summaryUrl = dataRes.reportReviewPath + '&create_date=' + data.create_date + '&id=' + data.id + '&type=' + data.type + '&create_dept=' + this.userInfo.dept_id;
              if(data.ids){
                this.summaryUrl = this.summaryUrl + '&ids='+ data.ids
              }else {
                this.summaryUrl = this.summaryUrl + '&ids=null'
              }
              this.summaryDiaShow = true;
            })
          }
        })
      }

    },
    // 报表 保存调用iframe 接口
    headSubmitReport(){
      let iframe = this.$refs.iframeSummary
      iframe.contentWindow.postMessage('save','*')
      console.log("调用保存接口")
    },
    //详情/保存后的汇总数据
    rpTaskAttributeDetail() {
      rpTaskAttributeDetail(this.query.id,this.type).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data.dataForm;
          this.rpTaskAttributeMap = data;
          let list = new Array();
          this.properties.forEach((item) => {
            if (item.isSummary == 1) {
              list.push({
                lable: item.fieldName,
                value: data[item.fieldCode]
              })
            }
          })
          this.summaryList = list;
        }
      })
    },
    //保存
    headSave() {
      this.$loading();
      let data = {
        id: this.query.id,
        templateCode: this.query.templateCode,
        dataStatus: 1,
        rpTaskAttributeMap: this.rpTaskAttributeMap,
        templateClassify: this.templateClassify
      }
      this.rpTaskAttributeSave(data);
      this.$loading().close();
    },
    //提交
    headSubmit() {
      this.$loading();
      if (this.templateClassify == '1') {
        let data = {
          id: this.query.id,
          templateCode: this.query.templateCode,
          dataStatus: 2,
          rpTaskAttributeMap: this.rpTaskAttributeMap,
          status: 2,
        }
        this.formProcess.id = this.query.id;
        this.formProcess.data = this.rpTaskAttributeMap;
        this.formProcess.deptId = this.userInfo.dept_id
        this.formProcess.templateClassify = this.templateClassify;
        summaryAllList(this.query.unitId, this.query.captureTaskId).then(res => {
          if (!res.data.success) {
            //下级有未审核完成的不能提交
            this.$message.warning(res.data.msg);
            return;
            /*this.$confirm(res.data.msg, {
              confirmButtonText: this.$t('确认'),
              cancelButtonText: this.$t('取消'),
              type: "warning"
            }).then(() => {
              this.rpTaskAttributeSubmit(data);
            })*/
          } else {
            this.disabled = true;
            this.type = 'view'
            this.rpTaskAttributeSubmit(data);
          }
        })
        this.$loading().close();
      } else {
        let data = {
          id: this.query.id,
          templateCode: this.query.templateCode,
          dataStatus: 2,
          status: 2,
          templateClassify: this.templateClassify
        }
        this.formProcess.id = this.query.id;
        this.formProcess.url = this.summaryUrl;
        this.formProcess.deptId = this.userInfo.dept_id
        this.formProcess.templateClassify = this.templateClassify;
        summaryAllList(this.query.unitId, this.query.captureTaskId).then(res => {
          if (!res.data.success) {
            //下级有未审核完成的不能提交
            this.$message.warning(res.data.msg);
            return;
          } else {
            this.disabled = true;
            this.type = 'view'
            this.rpTaskAttributeSubmit(data);
            let iframe = this.$refs.iframeSummary
            iframe.contentWindow.postMessage('save','*')
            this.summaryDiaShow = false;
          }
        })
        this.$loading().close();
      }
    },
    rpTaskAttributeSubmit(data) {
      this.handleStartProcess5(true,true).then((res) => {
        data.processInstanceId = res.data.data;
        rpTaskAttributeSaveSummarize(data).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        })
      });
    },
    rpTaskAttributeSave(data) {
      rpTaskAttributeSaveSummarize(data).then((res) => {
        if (res.data.code == 200) {
          if (this.templateClassify == '1') {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
          this.summaryType = 'ok';
        }
      })
    },
    //汇总
    headSummary() {
      rpTaskAttributeReportClick(this.query).then((res) => {
        if (res.data.code == 200) {
          /*this.summaryType = 'ok';*/
          if (this.templateClassify == '1') {
            this.$message({
              message: "汇总成功",
              type: "success",
            });
            this.summaryText = res.data.msg;
            if (res.data.data.length == 0) return;
            var arr = (JSON.stringify(res.data.data) == "{}");
            if (arr == false) {
              let data = res.data.data;
              this.rpTaskAttributeMap = data;
              let list = new Array();
              this.properties.forEach((item) => {
                if (item.isSummary == 1) {
                  list.push({
                    lable: item.fieldName,
                    value: data[item.fieldCode]
                  })
                }
              })
              this.summaryList = list;
            }
            this.headSave()
          } else {
            let data = res.data.data;
            let templateCode = this.tableData[0].templateCode;
            rpTaskAttributeDetailReport(templateCode).then((respon) => {
              let dataRes = respon.data.data;
              this.summaryUrl = dataRes.reportFillPath + '&create_date=' + data.create_date + '&id=' + data.id + '&type=' + data.type + '&create_dept=' + this.userInfo.dept_id;
              if (data.ids) {
                this.summaryUrl = this.summaryUrl + '&ids=' + data.ids
              } else {
                this.summaryUrl = this.summaryUrl + '&ids=null'
              }
              this.summaryDiaShow = true;
              this.headSave()
            })
          }
        }
      })
    },
    //讲模板配置项添加到列表属性
    getTemplatePreview() {
      templatePreview(this.query.templateCode).then((res) => {
        if (res.data.code == 200) {
          if (this.templateClassify == '1') {
            if (this.type !== 'workflow') {
              this.headTitle = this.weaveDept + "-" + res.data.data[0].templateName;
            }
            let data = res.data.data;
            let properties = new Array();
            data.forEach((item) => {
              item.properties.forEach((i) => {
                properties.push(i);
              })
            })
            this.properties = properties;
            properties.forEach((item) => {
              if (item.type == 5) {
                this.tableOption.column.push({
                  label: item.fieldName,
                  prop: item.fieldCode,
                  bind: 'rpTaskAttributeMap.' + item.fieldCode,
                  align: "center",
                  dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=` + item.dictionaryCode,
                  props: {
                    label: 'dictValue',
                    value: 'dictKey',
                  },
                })
              } else {
                this.tableOption.column.push({
                  label: item.fieldName,
                  prop: item.fieldCode,
                  bind: 'rpTaskAttributeMap.' + item.fieldCode,
                  align: "center",
                })
              }
              this.gridShow = true;
            })
          } else {
            this.gridShow = true;
            if (this.type !== 'workflow') {
              this.headTitle = this.weaveDept + '-' + this.titleName;
            }
          }
        }
      })
    },
    // 搜索填报设置
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空填报设置搜索条件
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 获取汇总列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      summaryDetailsList(page.currentPage, page.pageSize, this.query.captureFormId, this.query.captureTaskId, this.query.unitId, this.query.templateCode, this.templateClassify, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
          if (this.tableData && this.tableData[0].templateClassify == "2") {
            this.menu = true;
            this.$refs.gridLayOut.option.menu = true;
          } else {
            this.menu = false;
            this.$refs.gridLayOut.option.menu = false;
          }
        }
      })
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = "汇总清单";
        this.formProcess.processId = process.id;
      });
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .avue-crud .el-table {
  height: calc(100vh - 344px) !important;
  max-height: calc(100vh - 344px) !important;
}

.summaryBox {
  width: 100%;
  background-color: rgb(233, 237, 243);
  display: flex;
  overflow-x: auto;
}

.summaryTitle {
  width: 50%;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

.summaryLittle {
  //width: 100px;
  padding: 0 10px;
  text-align: center;
  height: 70px;
  line-height: 70px;
  border-left: 1px solid #333333;
}

.summaryLittle:last-child {
  border-right: 1px solid #333333;
}

.iframeBox {
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  padding: 12px;
  z-index: 99;
}

.formContentBox {
  padding: 12px;
  height: calc(100% - 94px);
}

.iframeBox1 {
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  padding: 12px;
  z-index: 99;
}

.iframe {
  width: 100%;
  height: calc(100% - 28px);
  border: none;
}
</style>
